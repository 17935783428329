behaviours.addBehaviour(
    (() => {
        const capitalizeFirstLetter = (string: any) => string.charAt(0).toUpperCase() + string.slice(1);
        const attach = function () {
            document.querySelectorAll('div[data-background]').forEach((block) => {
                const background = block.getAttribute('data-background');
                const nextBlock = block.nextElementSibling;
                const previousBlock = block.previousElementSibling;

                if (!previousBlock?.hasAttribute('data-background' || !background)) {
                    return;
                }

                previousBlock.setAttribute('data-next-block', block.classList[0]);
                block.setAttribute('data-previous-block', previousBlock.classList[0]);

                if (!nextBlock?.hasAttribute('data-background') || !previousBlock) {
                    return;
                }

                const nextBackground = nextBlock.getAttribute('data-background') ?? '';

                if (block && block.hasAttribute('data-background') && block.getAttribute('data-pull-background') == 'bottom') {
                    block.classList.add(`${block.classList[0]}--backgroundBottom${capitalizeFirstLetter(nextBlock.getAttribute('data-background'))}`);
                } else if (nextBlock && nextBlock.hasAttribute('data-background') && nextBlock.getAttribute('data-pull-background') == 'top') {
                    nextBlock.classList.add(`${block.classList[0]}--backgroundTop${capitalizeFirstLetter(block.getAttribute('data-background'))}`);
                }  else if (block && block.hasAttribute('data-background') && block.getAttribute('data-pull-background') == 'top') {
                    block.classList.add(`${block.classList[0]}--backgroundTop${capitalizeFirstLetter(previousBlock.getAttribute('data-background'))}`);
                    previousBlock.setAttribute('data-next-block-pull-background', 'top')
                }


                block.setAttribute('data-next-background', nextBackground);
                previousBlock.setAttribute('data-next-background', background);
            });
        };

        return {
            attach: attach,
        };
    })(),
);
