import {styledForm} from "./src/styledForm";
import {Behaviour} from "../../../global/scripts/behaviour";

const formBehaviour: Behaviour = (() => {

    const attach = (context: HTMLElement) => {
        context.querySelectorAll<HTMLFormElement>('.form').forEach((form) => {
            styledForm.initAll(form);
        });
    }

    return  {
        attach
    }
})();

jQuery(document).on('gform_post_render', (e) => {
    document.querySelectorAll<HTMLFormElement>('.form').forEach((form) => {
        styledForm.initAll(form);
    });
});

behaviours.addBehaviour(formBehaviour);
