// Global scss
import './global/styles/global_styles/global_styles.scss';

// Global css
const GlobalCssFiles = require.context('./global/styles/variables/css/', true);
GlobalCssFiles.keys().forEach(GlobalCssFiles);

// Main scripts
import './global/scripts/behaviour';

import './global/scripts/scrolledBehaviour';
import './global/scripts/blocks';

// Fonts
import './global/fonts/roboto/stylesheet.css';

// Components
const componentFiles = require.context('./elements', true, /^(?!.*_.*).*\.(scss|js|ts)$/);
componentFiles.keys().forEach(componentFiles);

// Woocommerce
const woocommerceFiles = require.context('./woocommerce', true, /^.*\.(scss|js|ts)$/);
woocommerceFiles.keys().forEach(woocommerceFiles);

// Qonfi
const qonfiFiles = require.context('./qonfi', true, /^.*\.(scss|js|ts)$/);
qonfiFiles.keys().forEach(qonfiFiles);

console.log('main.ts');
