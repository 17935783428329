// @ts-nocheck
setTimeout(function () {
    jQuery.scroll_to_notices = function (scrollElement) {
        if (scrollElement.length) {
            const offset = jQuery('.header').height() + 20;
            jQuery('html, body').animate({
                scrollTop: (scrollElement.offset().top - offset)
            }, 1000);
        }
    };
}, 1000);
