import 'plyr/src/sass/plyr.scss';
import { Behaviour } from '../../../global/scripts/behaviour';

const initVideo = function (element: HTMLElement, module: any) {
    const Plyr = module.default as any;

    const hero = element.closest('.hero');

    const autoplay = element.getAttribute('data-autoplay') === 'true';
    const background = element.getAttribute('data-background') === 'true';

    let playerConfig: any = {
        clickToPlay: undefined,
    };

    if (background) {
        playerConfig = {
            ...playerConfig,
            controls: [],
            fullscreen: {
                enabled: false,
                fallback: false,
            },
            vimeo: {
                background: true,
            },
            loop: {
                active: true,
            },
        };
    }

    if (autoplay) {
        playerConfig.clickToPlay = false;
    }

    const player = new Plyr(element, playerConfig);

    if (autoplay) {
        player.on('ready', () => {
            player.muted = true;
            player.play();
        });
    }

    if (hero) {
        player.on('playing', () => {
            hero.classList.add('hero--videoStarted');
        });
    }

    document.addEventListener('slideChange', () => {
        if(!hero) {
            player.pause();
        }
    });

};

const videoBehaviour: Behaviour = (() => {

    const attach = function (context: HTMLElement) {
        const videos = context.querySelectorAll('.video') as NodeListOf<HTMLElement>;

        if (videos.length === 0) {
            return false;
        }

        import(/* webpackChunkName: "video" */ "plyr").then(module => {
            videos.forEach(video => initVideo(video, module));
        });
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(videoBehaviour);
