import { Behaviour } from '../../../global/scripts/behaviour';

const teaserProduct: Behaviour = (function () {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.teaserProduct');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block, context));
    };

    const init = function (block: HTMLElement, context: HTMLElement) {

    };

    return {
        attach: attach
    }

})();

behaviours.addBehaviour(teaserProduct);