import {Behaviour} from "../../global/scripts/behaviour";
import {floatLabels} from "../../elements/molecule/form/src/floatLabels";

const checkoutFormBehavior: Behaviour = (() => {
    const attach = (context: HTMLElement) => {
        context.querySelectorAll<HTMLFormElement>('form.woocommerce-checkout, form.woocommerce-form-login, form.woocommerce-form-register').forEach((form) => {
            floatLabels.initForm(form);
        });
    }

    return {
        attach
    }
})();

behaviours.addBehaviour(checkoutFormBehavior);
