import {Behaviour} from "../../global/scripts/behaviour";

const cartFormHeaderBehaviour: Behaviour = (() => {
    const attach = (context: HTMLElement) => {
        const cartForm = context.querySelector('form.woocommerce-cart-form');
        const continueButton = context.querySelector('.wc-proceed-to-checkout a.button');
        const totalPrice = context.querySelector('.order-total bdi');

        if (!cartForm || !continueButton || !totalPrice) {
            return false;
        }

        const {header, priceField, buttonWrapper} = createHeader();
        cartForm.parentElement!.insertBefore(header, cartForm);

        priceField.innerHTML = totalPrice.innerHTML;
        const clonedButton = continueButton.cloneNode(true) as HTMLDivElement;
        clonedButton.classList.add('cartFormHeader__button');
        buttonWrapper.append(clonedButton);


        jQuery(document).ajaxComplete(function () {
            const totalPrice = context.querySelector('.order-total bdi');
            if (totalPrice) {
                priceField.innerHTML = totalPrice.innerHTML;
            }
        });
    }

    const createHeader = () => {
        const baseClass = 'cartFormHeader';

        const header = document.createElement('div');
        header.classList.add(baseClass);

        const title = document.createElement('h2');
        title.classList.add(`${baseClass}__title`)
        title.innerHTML = 'Winkelmand';

        const priceWrapper = document.createElement('div');
        priceWrapper.classList.add(`${baseClass}__price`);

        const priceLabel = document.createElement('div');
        priceLabel.innerHTML = 'Totaalbedrag'
        priceLabel.classList.add(`${baseClass}__priceLabel`);
        priceWrapper.append(priceLabel);

        const priceField = document.createElement('div');
        priceField.classList.add(`${baseClass}__priceValue`);
        priceField.innerHTML = '€ 0,00';
        priceWrapper.append(priceField);

        const buttonWrapper = document.createElement('div');
        buttonWrapper.classList.add(`${baseClass}__buttonWrapper`);

        header.append(title);
        header.append(priceWrapper);
        header.append(buttonWrapper);

        return {
            header,
            priceField,
            buttonWrapper
        };
    }

    return {
        attach
    }
})();

behaviours.addBehaviour(cartFormHeaderBehaviour);
