import {floatLabels} from "../../../molecule/form/src/floatLabels";

export const quantity = (() => {
    const attach = (html: HTMLElement) => {

        // Variation table rows
        html.querySelectorAll<HTMLElement>('.woocommerce-variation-add-to-cart .quantity, .productHero__form .quantity').forEach((element) => {
            styleQuantityInput(element);
        });
    }

    const styleQuantityInput = (element: HTMLElement) => {
        const label = element.querySelector('label');
        const input = element.querySelector('input');

        if (!label || !input) {
            return;
        }

        label.classList.remove('screen-reader-text');
        floatLabels.initSingle(input);
    }

    return {
        attach
    }
})();
