import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

export const GLS = (() => {
    const attach = (html: HTMLElement) => {

        jQuery(document).ajaxComplete(function () {
            const glsPopup = document.querySelector<HTMLDivElement>('.GLS-pickup');
            const openButton = document.querySelector<HTMLButtonElement>('.GLS-pick-location');
            const closeButton = document.querySelector<HTMLButtonElement>('.GLS-pickup__close');
            const submitButton = document.querySelector<HTMLButtonElement>('.GLS-pickup__validate');
            const overlay = document.querySelector<HTMLDivElement>('.GLS-pickup__overlay');

            glsPopup && openButton && openButton.addEventListener('click', () => {
                disableBodyScroll(glsPopup);
            });

            glsPopup && closeButton && closeButton.addEventListener('click', () => {
                enableBodyScroll(glsPopup);
            });

            glsPopup && overlay && overlay.addEventListener('click', () => {
                enableBodyScroll(glsPopup);
            });

            glsPopup && submitButton && submitButton.addEventListener('click', () => {
                enableBodyScroll(glsPopup);
            });

            const {address, fields} = getAddress();

            fields.forEach(field => {
                field?.addEventListener('change', () => {
                    const {address} = getAddress();
                    updateAddress(address);
                });
            });

            openButton ? hideAddress() : showAddress(address);
        });
    }

    const showAddress = (address: string) => {
        if (document.querySelector('.paymentAndSending__sendingAddress')) {
            return;
        }

        const addressContainer = document.querySelector('.paymentAndSending__sending');
        const addressElement = document.createElement('div');
        addressElement.classList.add('paymentAndSending__sendingAddress');
        addressContainer?.appendChild(addressElement);
        addressElement.innerHTML = address;
    }

    const updateAddress = (address: string) => {
        const addressElement = document.querySelector('.paymentAndSending__sendingAddress');
        if (!addressElement) {
            return;
        }

        addressElement && (addressElement.innerHTML = address);
    }


    const getAddress = () => {
        const title = document.querySelector('#checkout-text-send-to-address')

        const firstNameField = document.querySelector<HTMLInputElement>('#billing_first_name');
        const lastNameField = document.querySelector<HTMLInputElement>('#billing_last_name');
        const companyField = document.querySelector<HTMLInputElement>('#billing_company');
        const countryField = document.querySelector<HTMLSelectElement>('#billing_country');
        const postcodeField = document.querySelector<HTMLSelectElement>('#billing_postcode');
        const houseNumberField = document.querySelector<HTMLSelectElement>('#billing_house_number');
        const houseNumberSuffixField = document.querySelector<HTMLSelectElement>('#billing_house_number_suffix');
        const streetField = document.querySelector<HTMLSelectElement>('#billing_street_name');
        const cityField = document.querySelector<HTMLSelectElement>('#billing_city');

        const shippingAdressField = document.querySelector<HTMLInputElement>('#ship-to-different-address-checkbox');
        const shippingFirstNameField = document.querySelector<HTMLInputElement>('#shipping_first_name');
        const shippingLastNameField = document.querySelector<HTMLInputElement>('#shipping_last_name');
        const shippingCompanyField = document.querySelector<HTMLInputElement>('#shipping_company');
        const shippingCountryField = document.querySelector<HTMLSelectElement>('#shipping_country');
        const shippingPostcodeField = document.querySelector<HTMLSelectElement>('#shipping_postcode');
        const shippingHouseNumberField = document.querySelector<HTMLSelectElement>('#shipping_house_number');
        const shippingHouseNumberSuffixField = document.querySelector<HTMLSelectElement>('#shipping_house_number_suffix');
        const shippingStreetField = document.querySelector<HTMLSelectElement>('#shipping_street_name');
        const shippingCityField = document.querySelector<HTMLSelectElement>('#shipping_city');


        let addressRows = [];
        if (title) {
            addressRows.push(`<strong>${title.innerHTML}</strong>:`);
        }

        if (shippingAdressField?.checked) {
            if (shippingFirstNameField?.value || shippingLastNameField?.value) {
                addressRows.push(`${shippingFirstNameField?.value} ${shippingLastNameField?.value}`.trim());
            }
            if (shippingCompanyField?.value) {
                addressRows.push(shippingCompanyField.value);
            }
            if (shippingStreetField?.value || shippingHouseNumberField?.value || shippingHouseNumberSuffixField?.value) {
                addressRows.push(`${shippingStreetField?.value} ${shippingHouseNumberField?.value} ${shippingHouseNumberSuffixField?.value}`.trim());
            }
            if (shippingPostcodeField?.value || shippingCityField?.value) {
                addressRows.push(`${shippingPostcodeField?.value} ${shippingCityField?.value}`.trim());
            }
            if (shippingCountryField?.value) {
                addressRows.push(shippingCountryField.options[shippingCountryField.selectedIndex].text);
            }
        } else {
            if (firstNameField?.value || lastNameField?.value) {
                addressRows.push(`${firstNameField?.value} ${lastNameField?.value}`.trim());
            }
            if (companyField?.value) {
                addressRows.push(companyField.value);
            }
            if (streetField?.value || houseNumberField?.value || houseNumberSuffixField?.value) {
                addressRows.push(`${streetField?.value} ${houseNumberField?.value} ${houseNumberSuffixField?.value}`.trim());
            }
            if (postcodeField?.value || cityField?.value) {
                addressRows.push(`${postcodeField?.value} ${cityField?.value}`.trim());
            }
            if (countryField?.value) {
                addressRows.push(countryField.options[countryField.selectedIndex].text);
            }
        }

        const allFields = [
            firstNameField,
            lastNameField,
            companyField,
            countryField,
            postcodeField,
            houseNumberField,
            houseNumberSuffixField,
            streetField,
            cityField,
            shippingAdressField,
            shippingFirstNameField,
            shippingLastNameField,
            shippingCompanyField,
            shippingCountryField,
            shippingPostcodeField,
            shippingHouseNumberField,
            shippingHouseNumberSuffixField,
            shippingStreetField,
            shippingCityField
        ];

        return {
            address: addressRows.join('<br />'),
            fields: allFields
        };
    }

    const hideAddress = () => {
        const address = document.querySelector('.paymentAndSending__sendingAddress');
        address && address.remove();
    }

    return {
        attach
    }
})();

behaviours.addBehaviour(GLS);
