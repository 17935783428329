// Throttle function
export const throttle = (fn: Function, wait: number = 300) => {
    let inThrottle: boolean;
    let lastFn: ReturnType<typeof setTimeout>;
    let lastTime: number;
    return function (this: any) {
        const context = this;
        const args = arguments;
        if (!inThrottle) {
            fn.apply(context, args);
            lastTime = Date.now();
            inThrottle = true;
        } else {
            clearTimeout(lastFn);
            lastFn = setTimeout(() => {
                if (Date.now() - lastTime >= wait) {
                    fn.apply(context, args);
                    lastTime = Date.now();
                }
            }, Math.max(wait - (Date.now() - lastTime), 0));
        }
    };
};

// Debounce function
export const debounce = (fn: Function, wait: number = 300) => {
    let timeout: ReturnType<typeof setTimeout> | null;
    return function (this: any) {
        const context = this;
        const args = arguments;
        const later = () => {
            timeout = null;
            fn.apply(context, args);
        };
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait);
    };
};
