import {Behaviour} from "../../../global/scripts/behaviour";
import {debounce} from "../../../global/scripts/thottle";

const productDetail: Behaviour = (() => {

    const attach = (context: HTMLElement) => {
        const parent = context.querySelector<HTMLElement>('.blockProductDetail');
        const block = context.querySelector<HTMLElement>('.productDetails');
        const content = context.querySelector<HTMLElement>('.productDetails__content');
        const button = context.querySelector<HTMLButtonElement>('.productDetails button');

        if (!block || !content || !button || !parent) {
            return;
        }

        const checkOverflow = () => {
            block.classList.remove('productDetails--overflow');
            console.log(content.offsetHeight, block.offsetHeight);
            if (content.offsetHeight > block.offsetHeight) {
                block.classList.add('productDetails--overflow');
            } else {
                block.classList.remove('productDetails--overflow');
            }
        };

        const debouncedCheckOverflow = debounce(checkOverflow);
        window.addEventListener('resize', debouncedCheckOverflow);
        checkOverflow();

        button.addEventListener('click', () => {
            block.classList.toggle('productDetails--open');
            parent.classList.toggle('blockProductDetail--open');
            window.removeEventListener('resize', debouncedCheckOverflow);
        });
    }

    return {
        attach
    }
})()

behaviours.addBehaviour(productDetail);
