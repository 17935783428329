import {computePosition, shift, Placement, autoPlacement, flip, autoUpdate} from '@floating-ui/dom';
import {Behaviour} from "../../../global/scripts/behaviour";

const HeaderBehaviour: Behaviour = {
    attach: function (context: HTMLElement) {

        if (!context.querySelector<HTMLElement>('.submenu')) {
            return;
        }

        context.querySelectorAll<HTMLElement>('.submenu').forEach((submenu) => {
            const navItem = submenu.closest<HTMLElement>('.navigation__item')
                || submenu.closest<HTMLElement>('.header__accountAction');

            if (!navItem) {
                return;
            }

            const calculatePosition = () => {
                if (navItem) {
                    computePosition(navItem, submenu, {
                        middleware: [autoPlacement({
                            allowedPlacements: ['bottom-start', 'bottom-end'],
                        })],
                    }).then(({x, y}) => {
                        Object.assign(submenu.style, {
                            left: `${x}px`,
                            top: `${y}px`,
                        });
                    });
                }
            }

            const cleanup = autoUpdate(
                navItem,
                submenu,
                calculatePosition,
            );
        });
    },
}

behaviours.addBehaviour(HeaderBehaviour);
