
export const paymentMethods = (() => {
    const attach = (html: HTMLElement) => {
        const reviewOrderWrapper = html.querySelector('.woocommerce-checkout-review-order');

        if (!reviewOrderWrapper) {
            return;
        }

        const paymentAndSendingWrapper = document.createElement('div');
        paymentAndSendingWrapper.classList.add('paymentAndSending');

        // Create payment wrapper
        const paymentMethodWrapper = document.createElement('div');
        paymentMethodWrapper.classList.add('paymentAndSending__payment');
        paymentMethodWrapper.innerHTML = '<h2 class="paymentAndSending__title">Betaling</h2>';
        paymentAndSendingWrapper.append(paymentMethodWrapper);

        const paymentContent = html.querySelector('.woocommerce-checkout-payment');
        if (paymentContent) {
            paymentMethodWrapper.appendChild(paymentContent);
        }

        // Create sending wrapper
        const sendingWrapper = document.createElement('div');
        sendingWrapper.classList.add('paymentAndSending__sending');
        sendingWrapper.innerHTML = '<h2 class="paymentAndSending__title">Verzending</h2>';
        paymentAndSendingWrapper.append(sendingWrapper);

        const sendingContent = html.querySelector('.GLS-shipping-options');
        if (sendingContent) {
            sendingWrapper.appendChild(sendingContent);
        }

        reviewOrderWrapper.append(paymentAndSendingWrapper);
    }

    return {
        attach
    }
})();

behaviours.addBehaviour(paymentMethods);
