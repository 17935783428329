import { Behaviour } from '../../../global/scripts/behaviour';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const blockProductTeasers: Behaviour = (function () {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockFaqTeasers');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block, context));
    };

    const init = function (block: HTMLElement, context: HTMLElement) {
        const baseClass = 'blockFaqTeasers';
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`);
        const navClass = "iconHolder";
        const buttonNext = block?.querySelector<HTMLElement>(
            `.${navClass}--next`
        );
        const buttonPrev = block?.querySelector<HTMLElement>(
            `.${navClass}--prev`
        );

        if (!sliderElement) {
            return false;
        }

        const swiper = new Swiper(sliderElement, {
            modules: [Navigation],
            wrapperClass: `${baseClass}__items`,
            slideClass: `${baseClass}__item`,
            speed: 400,
            slidesPerView: 'auto',
            spaceBetween: 20,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                900: {
                    slidesPerView: 2,
                },
            }
        });
    };

    return {
        attach: attach
    }

})();

behaviours.addBehaviour(blockProductTeasers);
