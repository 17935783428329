import {styledSelect} from "../../../molecule/form/src/styledSelect";
import {floatLabels} from "../../../molecule/form/src/floatLabels";

export const variations = (() => {
    const attach = (html: HTMLElement) => {

        // Variation table rows
        html.querySelectorAll<HTMLTableRowElement>('.variations tr').forEach((variation) => {
            styleVariationTableRow(variation);
        });
    }

    const styleVariationTableRow = (variation: HTMLTableRowElement) => {
        const labelCell = variation.querySelector<HTMLTableCellElement>('th.label')
        const labelText = labelCell?.querySelector('label')?.innerHTML;
        const valueCell = variation.querySelector<HTMLSelectElement>('.value');
        const select = valueCell?.querySelector<HTMLSelectElement>(' select');

        if (!labelText || !select || !valueCell) {
            return;
        }

        labelCell.remove();

        const placeholderOption = document.createElement('option');
        placeholderOption.value = '';
        select.prepend(placeholderOption);

        const label = document.createElement('label');
        label.innerHTML = labelText.replace(':', '');
        valueCell.prepend(label);

        styledSelect.init(select, {
            unsetSelectedOption: true
        });

        floatLabels.initSingle(select);
    }

    return {
        attach
    }
})();
