import { Behaviour } from '../../../global/scripts/behaviour';

export interface SearchBarBehaviour extends Behaviour {
    reset: (menu: HTMLElement) => void;
}

const SearchBarBehaviour: SearchBarBehaviour = (() => {
    const baseClass = 'searchBar';

    const attach = function (context: HTMLElement) {
        const searchBar = context.querySelector(`.${baseClass}`) as HTMLElement;
        const menuContent = context.querySelector(`.${baseClass}__content`) as HTMLElement;

        // No searchBar found, so exit
        if (!(searchBar)) {
            return false;
        }


        // Behaviour already attached, so exit
        if (searchBar.classList.contains(`${baseClass}--behaviourAttached`)) {
            return false;
        }

        searchBar.classList.add(`${baseClass}--behaviourAttached`);

        init(searchBar, context);

        document.addEventListener('mobileDrawerOpened', () => {
            reset(searchBar);
        });
    };


    const init = (searchBar: HTMLElement, context: HTMLElement) => {


        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                const input = searchBar.querySelector('.searchForm__input');
                searchBar.classList.remove('searchBar--active');
                if (input) {
                    input.blur();
                    input.value = '';
                }
                context.querySelectorAll('.searchTrigger').forEach((trigger) => {
                    trigger.classList.remove('searchTrigger--active');
                });
                document.documentElement.classList.remove('searchOpen');
            }
        });

        context.querySelectorAll('.searchTrigger').forEach((trigger) => {
            if (!trigger.classList.contains('initialized')) {
                trigger.classList.add('initialized');
                trigger.addEventListener('click', () => {

                    // Open search bar in header
                    const isActive = searchBar.classList.toggle('searchBar--active');
                    trigger.classList.toggle('searchTrigger--active', isActive);
                    document.documentElement.classList.toggle('searchOpen', isActive);

                    const input = searchBar.querySelector('.searchForm__input');
                    if (isActive && input) {
                        input.value = '';
                        input.focus();
                    } else if(input)  {
                        input.blur();
                        input.value = '';
                    }
                });
            }
        });

    };

    const reset = function (searchBar: HTMLElement) {
        searchBar.classList.remove('searchBar--active');
    };

    return {
        attach: attach,
        reset: reset,
    };
})();

behaviours.addBehaviour(SearchBarBehaviour);
