import { Behaviour } from '../../../global/scripts/behaviour';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import {debounce} from "../../../global/scripts/thottle";

const blockProductTeasers: Behaviour = (function () {

    const attach = function (context: HTMLElement) {
        const sliders = context.querySelectorAll<HTMLElement>('.blockProductTeasers__slider');

        if (sliders.length === 0) {
            return;
        }

        sliders.forEach(slider => init(slider, context));
    };

    const init = function (slider: HTMLElement, context: HTMLElement) {
        const baseClass = 'blockProductTeasers';
        const block = slider.closest(`.${baseClass}`) || slider;
        const navClass = "iconHolder";

        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`);
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--prev`);

        const isCart = document.body.classList.contains('woocommerce-cart');

        const hideNavigation = () => {
            if(block) {
                block.classList.toggle(`${baseClass}--navigationEnabled`, block.querySelectorAll('.iconHolder--lock').length == 0);
            }
        };

        const items = slider.closest('.blockText--textWidthLimited') ? 3 : 4;

        const swiper = new Swiper(slider, {
            modules: [Navigation],
            wrapperClass: `${baseClass}__items`,
            slideClass: `${baseClass}__item`,
            speed: 400,
            init: false,
            slidesPerView: 'auto',
            spaceBetween: 20,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                1300: {
                    slidesPerView: isCart ? 1 : items,
                },
            },
            on: {
                afterInit: function () {
                    hideNavigation();
                }
            }
        });

        swiper.init();

        const debouncedCheckOverflow = debounce(hideNavigation, 100);
        window.addEventListener('resize', debouncedCheckOverflow);
    };

    return {
        attach: attach
    }

})();

behaviours.addBehaviour(blockProductTeasers);
