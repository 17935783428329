import {styledSelect} from "./styledSelect";
import {floatLabels} from "./floatLabels";

export const styledForm = (() => {

    const initAll = (form: HTMLFormElement) => {

        // Selects
        form.querySelectorAll('select').forEach((select) => styledSelect.init(select));

        // Float labels
        floatLabels.initForm(form);
    }

    return {
        initAll
    }
})()
