import Accordion from 'accordion-js';
import {Behaviour} from "../../../global/scripts/behaviour";

const blockAccordion: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const baseClass = 'accordion';
        const isMobile = window.matchMedia('(max-width: 767px)').matches;

        context.querySelectorAll<HTMLElement>(`.${baseClass}`).forEach(element => {
            const isFooter = element.classList.contains(`${baseClass}--typeFooter`);
            const itemCount = element.querySelectorAll(`.${baseClass}__item`).length;
            const indexArray = Array.from(Array(itemCount).keys());

            const openByDefault = isFooter && !isMobile ? indexArray : [];

            const accordion = new Accordion(element, {
                ariaEnabled: false,
                showMultiple: openByDefault.length > 1,
                elementClass: `${baseClass}__item`,
                triggerClass: `${baseClass}__itemTrigger`,
                panelClass: `${baseClass}__itemPanel`,
                openOnInit: openByDefault,
                activeClass: `${baseClass}__item--isActive`,
            });

        });

    };

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(blockAccordion);

