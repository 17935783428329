import { Behaviour } from '../../../global/scripts/behaviour';
import Swiper from 'swiper';

const blockProductTeasers: Behaviour = (function () {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockPages');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block, context));
    };

    const init = function (block: HTMLElement, context: HTMLElement) {
        const baseClass = 'blockPages';
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`);

        if (!sliderElement) {
            return false;
        }

        const swiper = new Swiper(sliderElement, {
            wrapperClass: `${baseClass}__items`,
            slideClass: `${baseClass}__item`,
            speed: 400,
            slidesPerView: 'auto',
            spaceBetween: 20,
            pagination: {
                el: `.${baseClass}__bullets`,
                type: 'bullets',
                bulletClass: `${baseClass}__bullet`,
                bulletActiveClass: `${baseClass}__bullet--active`,
                clickable: true,
            },
            breakpoints: {
                1300: {
                    slidesPerView: 4,
                },
            }
        });
    };

    return {
        attach: attach
    }

})();

behaviours.addBehaviour(blockProductTeasers);
