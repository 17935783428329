behaviours.addBehaviour(
    (() => {
        const attach = function (context: HTMLElement) {
            const links = context.querySelectorAll('.jsLink') as NodeListOf<HTMLElement>;

            if (links.length === 0) {
                return;
            }

            links.forEach((link) => init(link));
        };

        const init = function (link: HTMLElement) {
            const href = link.getAttribute('data-href') ?? '';
            const target = link.getAttribute('data-target') ?? '';

            link.addEventListener('click', () => {
                if (target && target === '_blank') {
                    window.open(href);
                } else {
                    const newLocation = new Location();
                    newLocation.href = href;
                    window.location = newLocation;
                }
            });
        };

        return {
            attach: attach,
        };
    })(),
);
