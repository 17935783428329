import {floatLabels} from "../../elements/molecule/form/src/floatLabels";

export const coupon = (() => {
    const attach = (html: HTMLElement) => {
        // Variation table rows
        html.querySelectorAll<HTMLElement>('.shop_table .coupon').forEach((element) => {
            styleCouponInput(element);
        });

        jQuery(document).ajaxComplete(function () {
            document.querySelectorAll<HTMLElement>('.shop_table .coupon').forEach((element) => {
                setTimeout(() => {
                    styleCouponInput(element);
                }, 1);
            });
        });
    }

    const styleCouponInput = (element: HTMLElement) => {
        const label = element.querySelector('label');
        const input = element.querySelector('input');

        if (!label || !input) {
            return;
        }

        label.classList.remove('screen-reader-text');
        floatLabels.initSingle(input);
    }

    return {
        attach
    }
})();

behaviours.addBehaviour(coupon);
